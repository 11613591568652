import gql from 'graphql-tag';

export default gql`
mutation updateUserName(
  $email: String!,
  $newName: String!,
) {
  update_users(
    where: {
      email: {_eq: $email}
    }, 
    _set: {full_name: $newName}
  ) {
    affected_rows
  }
}
`;
