import gql from 'graphql-tag';

export default gql`
mutation updateUserEmailConfirmation(
  $email: String!, 
  $emailConfirmed: Boolean!
) {
  update_users(
    where: {
      email: {_eq: $email}
    }, 
    _set: {email_confirmed: $emailConfirmed}
  ) {
    affected_rows
  }
}
`;
