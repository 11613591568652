import SendAnnouncementEmail from '@/graphQlQueries/mutations/sendInviteEmail';
import UpdateUserName from '@/graphQlQueries/mutations/updateUserName';
import GetUserByEmail from '@/graphQlQueries/queries/getUserByEmail';
import UpdateUserEmailConfirmation from '@/graphQlQueries/mutations/updateUserEmailConfirmation';

const login = {
  data() {
    return {
      passwordChallenge: false,
    };
  },
  methods: {
    async checkAwsUser(awsUser) {
      try {
        if (awsUser) {
          // there is a challenge
          if (awsUser.challengeName) {
            console.log('challenge', awsUser.challengeName);
            if (awsUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
              this.passwordChallenge = true;
            }
            if (awsUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
              await this.awsUserConfirmedAndEmailValidated(awsUser);
            } else {
              await this.$router.push({
                path: '/oops-something-went-wrong',
                query: { error: 'emailNotConfirmed' },
              }).catch((err) => {
                throw new Error(`Routing Error occurred! ${err}.`);
              });
            }
          } else if (awsUser.attributes.email_verified) {
            // all aws checks went good now check the hasura user
            await this.awsUserConfirmedAndEmailValidated(awsUser);
          } else {
            await this.$router.push({
              path: '/oops-something-went-wrong',
              query: { error: 'emailNotConfirmed' },
            }).catch((err) => {
              throw new Error(`Routing Error occurred! ${err}.`);
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async awsUserConfirmedAndEmailValidated(awsUser) {
      try {
        // instead of query hasura I can use the token to specify if a user exisits or not!!!
        const hasuraUserInfo = JSON.parse(awsUser.signInUserSession.getIdToken().payload['https://hasura.io/jwt/claims']);
        if (hasuraUserInfo && hasuraUserInfo['x-hasura-user-exists'] === 'false') {
          // sent the user to the setup page to create a new user and a new group
          await this.$router.push('/webmag-setup').catch((err) => {
            throw new Error(err);
          });
        } else if (
          hasuraUserInfo
          && hasuraUserInfo['x-hasura-user-exists'] === 'true'
        ) {
          const { data: { users: [hasuraUser] } } = await this.$apollo.query({
            query: GetUserByEmail,
            variables: {
              email: awsUser.attributes.email,
            },
          });
          if (!hasuraUser) {
            // sent the user to the setup page to create a new user and a new group
            await this.$router.push('/webmag-setup').catch((err) => {
              throw new Error(err);
            });
          } else if (
            hasuraUser
            && hasuraUser.user_id
            && !hasuraUser.deactivated
          ) {
            // confirm the email in hasura if email_confirm equals false
            if (hasuraUser.email_confirmed === false) {
              await this.$apollo.mutate({
                mutation: UpdateUserEmailConfirmation,
                variables: {
                  email: hasuraUser.email,
                  emailConfirmed: true,
                },
              });
            }
            // check for the register component and update the userName
            // if the is in the register process
            if (this.$options.name === 'SignUp') {
              // update the userName
              const userName = awsUser.attributes.name;
              if (userName) {
                await this.$apollo.mutate({
                  mutation: UpdateUserName,
                  variables: {
                    email: awsUser.attributes.email,
                    newName: userName,
                  },
                });
              }
              const user_email = awsUser.attributes.email;
              await this.$apollo.mutate({
                mutation: SendAnnouncementEmail,
                variables: {
                  subject: this.$t('register.invitedUserAnnouncementToWebmag.subject', { user_email }),
                  to: 'support@webmag.io',
                  html: this.$t('register.invitedUserAnnouncementToWebmag.text', { user_email }),
                },
              });
              fetch('https://hooks.slack.com/services/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', {
                method: 'POST',
                body: JSON.stringify({
                  text: this.$t('register.invitedUserAnnouncementToWebmag.slackMessage', { user_name: userName, user_email }),
                }),
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Network response was not ok.');
                  }
                  console.log('Slack message sent successfully!');
                })
                .catch((error) => {
                  console.error('Error:', error);
                });
            }
            await this.$router.push('/').catch((err) => {
              throw new Error(err);
            });
          } else if (hasuraUser.deactivated) {
            await this.$router.push('/webmag-deactivated').catch((err) => {
              throw new Error(`Routing Error occurred! ${err}.`);
            });
          } else if (!hasuraUser.email_confirmed) {
            // TODO: probably we can remove this snippet because it will never be reached
            // confirm the email
            console.error('AHA');
            const { data: { update_users: { affected_rows } } } = await this.$apollo.mutate({
              mutation: UpdateUserEmailConfirmation,
              variables: {
                email: hasuraUser.email,
                emailConfirmed: true,
              },
            });
            if (affected_rows) {
              // redirect to Dashboard
              await this.$router.push('/').catch((err) => {
                throw new Error(err);
              });
            } else {
              await this.$router.push({
                path: '/oops-something-went-wrong',
              }).catch((err) => {
                throw new Error(`Routing Error occurred! ${err}.`);
              });
            }
            console.log('affected rows', affected_rows);
          } else {
            await this.$router.push({
              path: '/oops-something-went-wrong',
              query: { error: 'emailNotConfirmed' },
            }).catch((err) => {
              throw new Error(`Routing Error occurred! ${err}.`);
            });
          }
        } else {
          await this.$router.push('/oops-something-went-wrong').catch((err) => {
            throw new Error(`Routing Error occurred! ${err}.`);
          });
        }
      } catch (e) {
        console.log(e);
        await this.$router.push('/oops-something-went-wrong').catch((err) => {
          throw new Error(`Routing Error occurred! ${err}.`);
        });
      }
    },
  },
};

export default login;
